<template>
  <div class="track-slider section" :class="{ 'track-slider--supplemented': supplemented }">
    <div class="track-slider__content">
      <div class="g-container">
        <div class="section-item">
          <div class="section-details">
            <p class="track-slider__title">{{ data.name }}</p>
          </div>
          <div class="track-slider__swiper">
            <div v-swiper:mySwiper="swiperOption">
              <div class="swiper-wrapper">
                <a
                  class="swiper-slide section-img"
                  v-for="track in data.tracks"
                  :key="track.id"
                  :ref="`track-${track.id}`"
                  :href="`/?channel=${track.id}`"
                  @click.prevent="onRedirect('/', { query: { channel: track.id } })"
                  @mouseenter="onMouseEnterTrack(track.id)"
                  @mouseleave="onMouseLeaveTrack(track.id)"
                >
                  <img :src="track.image" :alt="track.title">
                  <span class="track-slider__track-title" v-if="track.title" :title="track.title">{{ track.title }}</span>
                  <div class="track-slider__epg-wrapper">
                    <span
                      class="track-slider__epg"
                      v-if="track.epg"
                      :ref="`track-epg-${track.id}`"
                      :title="track.epg.title"
                    >
                      {{ track.epg.title }}
                    </span>
                  </div>
                </a>
              </div>
            </div>
            <div class="track-slider__navigation">
              <button
                class="track-slider__navigation-prev"
                :class="[`track-slider__navigation-prev-${data.id}`]"
              >
                <Arrow />
              </button>
              <button
                class="track-slider__navigation-next"
                :class="[`track-slider__navigation-next-${data.id}`]"
              >
                <Arrow />
              </button>
            </div>
          </div>
        </div>
        <Ad v-if="showAds && activeTrack" :data="activeTrack" />
      </div>
    </div>
  </div>
</template>

<script>

import deviceName from '@/utils/deviceName'

export default {
  name: 'TrackSlider',

  props: {
    data: {
      type: Object,
      required: true
    },
    supplemented: {
      type: Boolean,
      default: false
    },
    showAds: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Arrow: () => import('@/components/icons/Arrow'),
    Ad: () => import('@/components/Ad')
  },

  data () {
    return {
      activeTrackIndex: 0,
      swiperOption: {
        init: false,
        slidesPerView: 'auto',
        spaceBetween: 25,
        navigation: {
          prevEl: `.track-slider__navigation-prev-${this.data.id}`,
          nextEl: `.track-slider__navigation-next-${this.data.id}`
        }
      }
    }
  },

  mounted () {
    this.mySwiper.on('slideChange', () => {
      this.activeTrackIndex = this.mySwiper.activeIndex
    })

    this.mySwiper.init(this.swiperOption)
  },

  computed: {
    activeTrack () {
      return this.data?.tracks?.[this.activeTrackIndex] || null
    }
  },

  methods: {
    onRedirect (path, opts) {
      this.$emit('redirect', { path, opts })
    },

    onMouseEnterTrack (id) {
      if (!deviceName()) {
        const trackEl = this.$refs[`track-${id}`]?.[0]
        const trackEpgEl = this.$refs[`track-epg-${id}`]?.[0]
        if (trackEl && trackEpgEl) {
          trackEpgEl.style.transition = `transform ${trackEpgEl.offsetWidth / trackEl.offsetWidth * 2}s linear`
          setTimeout(() => {
            trackEpgEl.style.transform = `translateX(-${trackEpgEl.offsetWidth - trackEl.offsetWidth}px)`
          }, 1)
        }
      }
    },

    onMouseLeaveTrack (id) {
      const trackEl = this.$refs[`track-${id}`]?.[0]
      const trackEpgEl = this.$refs[`track-epg-${id}`]?.[0]
      if (trackEl && trackEpgEl) {
        trackEpgEl.style.transition = ''
        trackEpgEl.style.transform = ''
      }
    }
  },

  beforeDestroy () {
    if (this.mySwiper) { this.mySwiper.destroy() }
  }
}
</script>
